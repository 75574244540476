import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Analytics } from '@genoa/analytics'
import { BillerPropertyDetails, BillerPropertyPortalDetails, FlexLinks } from '@genoa/domain'
import { CO_PORTAL_SIGN_IN, deepReplaceContent, PORTAL_SIGN_IN } from '@genoa/screen-content'
import { indefiniteArticle } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { RentPortalSignInFormData, useContentOverhaul } from '../../../../hooks'
import { useAnalytics, useFlexLinks, useHelpLinks } from '../../../../providers'
import {
  Field,
  Headline1,
  Headline2,
  InlineButton,
  PasswordInput,
  PrimaryButton,
  SmallText,
  Text,
} from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { PrefillConfirmUnitBanner } from './direct-integration-unit-selection/PrefillConfirmUnitBanner'

type RentPortalSignInProps = {
  onBack: () => unknown
  onSignIn: (data: RentPortalSignInFormData) => unknown
  onForgotPassword: () => unknown
  onReset: () => void
  portal?: BillerPropertyPortalDetails
  property?: BillerPropertyDetails
  loading: boolean
  analyticsScreenName: Analytics.Screens
  analyticsEventWhatIfNoPortalClicked: Analytics.Events
  prefillIsActive: boolean
}

/*const portal = {
  fields: {
    username: {
      label: 'Username',
      name: 'username',
    },
    password: {
      label: 'Password',
      name: 'password',
    },
  },
}
*/
export const RentPortalSignIn = (props: RentPortalSignInProps) => {
  const theme = useTheme()
  const flexLinks = useFlexLinks()
  const analytics = useAnalytics()
  const helpLinks = useHelpLinks()
  const propertyName = props.property?.name || ''
  const portalDisplayName = props.portal?.display_name || ''
  const portalName = props.portal?.portal_name || propertyName || ''

  const { onboardingEnabled } = useContentOverhaul()
  const portalSignInContent = onboardingEnabled ? CO_PORTAL_SIGN_IN : PORTAL_SIGN_IN
  const content = deepReplaceContent(portalSignInContent, {
    propertyName,
    portalDisplayName,
    fallbackPortalDisplayName: portalDisplayName || 'your rent portal',
  })

  const fieldOneData = useMemo(() => {
    return {
      label: props.portal?.username_field_name ?? 'Username',
      name: 'username',
    }
  }, [props.portal])

  const fieldTwoData = useMemo(() => {
    return {
      label: props.portal?.password_field_name ?? 'Password',
      name: 'password',
    }
  }, [props.portal])

  const rentPortalSignInSchema = useMemo(
    () =>
      yup.object({
        [fieldOneData.name]: yup.string().required(`${fieldOneData.label} is required`),
        [fieldTwoData.name]: yup.string().required(`${fieldTwoData.label} is required`),
      }),
    [props.portal, fieldOneData, fieldTwoData]
  )

  const {
    control,
    handleSubmit,
    formState: { errors: errorsForm },
  } = useForm<RentPortalSignInFormData>({
    resolver: yupResolver(rentPortalSignInSchema),
  })

  const onSubmit = handleSubmit((data: RentPortalSignInFormData) => {
    props.onSignIn(data)
  })

  const colors = useMemo(() => {
    return props.portal
      ? {
          background: props.portal.background_color ?? theme.colors.background,
          foreground: props.portal.foreground_color ?? theme.colors.text[900],
          primaryButtonBackground: theme.colors.white,
          primaryButtonForeground: theme.colors.black,
          fieldBackground: theme.colors.whitesmoke,
          headerButtonForeground: theme.colors.white,
        }
      : {
          background: theme.colors.background,
          foreground: theme.colors.text[900],
        }
  }, [props.portal])

  const Header = useMemo(() => {
    if (props.portal && props.portal.logo_url) {
      return <RentPortalLogo style={{}} src={props.portal.logo_url} />
    }
    return (
      <Box alignItems={'center'}>
        <Headline2>{portalDisplayName}</Headline2>
        <SmallText>{props.property?.location?.address_line_1}</SmallText>
        {props.portal && [
          <Box key="hint-spacing" minH={'15px'} />,
          <SmallText key="hint-label" textAlign="center" style={{ maxWidth: '80%' }}>
            Login using your current portal username and password
          </SmallText>,
        ]}
      </Box>
    )
  }, [props.portal])

  const actions = useMemo(
    () => [
      {
        label: `What if I don't have ${indefiniteArticle(portalDisplayName)} ${portalDisplayName} account?`,
        action: () => {
          analytics.logEvent(props.analyticsEventWhatIfNoPortalClicked, {
            property: propertyName,
            portal: portalName,
          })

          helpLinks.open(FlexLinks.noRentPortal)
        },
      },
    ],
    [analytics, helpLinks.open, portalDisplayName, portalName, propertyName]
  )

  return (
    <BasePageLayout actions={actions} analyticsScreenName={props.analyticsScreenName}>
      <Box minH={'30px'} />

      <Headline1>Connect your rent portal</Headline1>

      <Box minH={'24px'} />

      {props.prefillIsActive && (
        <>
          <PrefillConfirmUnitBanner onReset={props.onReset} propertyName={propertyName} />
          <Box minH={'24px'} />
        </>
      )}

      <Text data-testid="RentPortalSignInBody">{content.BODY_WEB}</Text>
      <Box minH={'40px'} />
      <Box
        px="lg"
        borderRadius="12px"
        padding="20px 16px 40px 16px"
        background={`linear-gradient(180deg,${colors.background} 16%,white 0.1%,white 84%)`}
        boxShadow={'0px 1px 4px rgba(0, 0, 0, 0.2)'}
      >
        <Flex alignItems="center" justifyContent="center">
          {Header}
        </Flex>

        <Box minH={'59px'} />

        <Field
          control={control}
          label={fieldOneData.label}
          name={fieldOneData.name}
          // @ts-ignore
          error={errorsForm[fieldOneData.name]}
          disabled={props.loading}
          testID="RentPortalSignInFirstField"
        />

        <Box minH={'19px'} />

        <Field
          control={control}
          label={fieldTwoData.label}
          name={fieldTwoData.name}
          input={PasswordInput}
          // @ts-ignore
          error={errorsForm[fieldTwoData.name]}
          disabled={props.loading}
          testID="RentPortalSignInSecondField"
        />

        <Box minH={'59px'} />

        <Box>
          <PrimaryButton
            onClick={onSubmit}
            processing={props.loading}
            disabled={props.loading}
            testID="RentPortalSignInCTAButton"
          >
            Connect
          </PrimaryButton>
        </Box>
        <Box minH={'24px'} />

        {props.portal && (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <InlineButton
              onClick={props.onForgotPassword}
              disabled={props.loading}
              testID="RentPortalSignInForgotPassword"
            >
              <b>{content.FORGOT_PASSWORD}</b>
            </InlineButton>
          </Box>
        )}
      </Box>
    </BasePageLayout>
  )
}

const RentPortalLogo = styled('img')`
  max-width: 100%;
  height: 40px;
`
